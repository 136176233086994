/**
 *    -- INZ 2019 Navigation 2023 Javascript ------------------------------------------------------------------------------------------------------------------------
 *
 *    This is the Javascript file for Navigation 2023. This is loaded after the original site javascript and after the inz2019.js core javascript.
 *
 *    Because this is called after inz2019.js, the window.inz object is guaranteed to be available. DNA's custom jQuery build is also present.
 *
 *    General tips
 *    ------------
 *
 *    - Use jsdoc (https://jsdoc.app/) format to document your code. Be liberal with your comments - they are removed by the JSView when it serves the
 *      javascript resources in PRD, and well-commented code is a gift to future you and your co-workers!
 *
 *    - Wherever possible, do not rely on functionality that is provided via site.src.js. The longer term goal of the INZ2019 project is to remove the original
 *      site.src.js code from the application altogether. If you do need to depend on something from the old code, make sure you clearly identify it in the
 *      comments at the top of your js file to facilitate migration away at a future date.
 *
 *
 *    ES Support
 *    ----------
 *
 *    Internet Explorer (including IE11) are no longer supported browsers, so use of ES6 and beyond language features are now fair game.
 *    That said, we continue to use babel to generate theoretically compatible js for ES5-limited platforms.
 *
 */

/**
 *  Namespace object for the <Component> functionality.
 */
inz.nav2023 = {menu: null};


/**
 * Init method for the Navigation 2023 functionality; called from inz.inz2019Ready() (inz2019.js) on document ready.
 */
inz.nav2023.init = function()
{
    // console.log('nav2023 init');
    document.addEventListener('inz.navdata.loaded', (e)=>
    {
        // console.log('Navdata Loaded!', e);
        inz.nav2023.menu = new HamburgerMenu(inz.navData.data, inz.navData.siteNavRoot);
    });

    // determine the width of scrollbars in the browser and store it as a CSS variable for use in the hamburger stylesheet
    document.body.style.setProperty('--inz-scrollbar-width', `${window.innerWidth - document.body.clientWidth}px`);
};


class HamburgerMenu
{
    #navData;

    /**
     * The URL of the Site Nav Root.
     * @member {URL}
     */
    #siteNavRootUrl;

    #navItems;

    /**
     * An array of TopLevelNavItem objects representing the first-level items in the navigation tree.
     * This member is initialised by #loadNavData().
     * @member {TopLevelNavItem[]}
     */
    #topLevelItems;

    #menuButton;
    #dialog;

    /**
     * The nav_parent div element, initialised by #initDialog.
     * @member {Element}
     */
    #navParentDiv;

    /**
     * The crumbs ul element, initialised by #initDialog.
     * @member {Element}
     */
    #crumbsUl;

    /**
     * The root_menu li element, initialised by #initDialog.
     * @member {Element}
     */
    #rootMenuCrumb;

     /**
     * The root_menu button element, initialised by #initDialog.
     * @member {HTMLButtonElement}
     */
    #rootMenuButton;

    /**
     * The children div element, initialised by #initDialog.
     * @member {Element}
     */
    #childrenDiv;

    /**
     * The section_links div element, initialised by #initDialog.
     * @member {HTMLDivElement}
     */
    #sectionLinksDiv;

    /**
     * The section_links p element, initialised by #initDialog.
     * @member {HTMLParagraphElement}
     */
    #sectionLinksP;

    /**
     * The section_links ul element, initialised by #initDialog.
     * @member {HTMLUListElement}
     */
    #sectionLinksUl;

    /**
     * The return div element, initialised by #initDialog.
     * @member {HTMLDivElement}
     */
    #returnDiv;

    /**
     * The return p element, initialised by #initDialog.
     * @member {HTMLParagraphElement}
     */
    #returnP;

     /**
     * The return button element, initialised by #initDialog.
     * @member {HTMLButtonElement}
     */
    #returnButton;



    /**
     * Indicates which NavigationItem currently being displayed.
     * If null the root menu should be displayed; otherwise the specified NavItem should be displayed.
     * This member is initialised by #loadNavData().
     * @member {NavItem|null}
     */
    #currentItem;

    /**
     * Indicates which NavigationItem should be displayed when the menu is initially opened. Re-opening the menu will set the #currentItem to this value, effectively resetting the menu UI.
     * If null the root menu should be displayed; otherwise the specified NavItem should be displayed.
     * This member is initialised by #loadNavData().
     * @member {NavItem|null}
     */
    #initialItem;

    constructor(data, siteNavRootUrl)
    {
        this.#navData = data;
        this.#siteNavRootUrl = siteNavRootUrl;
        this.#navItems = {};
        this.#topLevelItems = [];

        this.#menuButton = document.getElementById('header_menu_2023');
        if(this.#menuButton) {this.#menuButton.addEventListener('click', (e)=>{this.#show();});}

        // add the empty dialog to the page body
        this.#initDialog();

        // add a key binding for F2 to toggle the menu
        document.body.addEventListener('keydown', event=>
        {
            if(this.#dialog && event.key === 'F2' && !event.ctrlKey && !event.altKey && !event.shiftKey && !event.metaKey)
            {
                if(this.#dialog.open)
                {
                    this.#hide();
                }
                else
                {
                    this.#show();
                }
                event.stopPropagation();
                event.preventDefault();
            }
        });

        // then parse the nav data
        this.#loadNavData();

        // this.#updateDialog();

    }

    #initDialog()
    {
        if(!this.#dialog)
        {
            // create the dialog
            this.#dialog = document.createElement('dialog');
            this.#dialog.setAttribute('id', 'hamburger_menu_2023');
            this.#dialog.innerHTML = '<nav class="menu_body">\n    <div class="close_bar">\n        <button><span class="icon"></span><span>Close</span></button>\n    </div>\n    <div class="upper_nav">\n        <div class="nav_parent">\n            <button class="nav_button nav_parent" title="Up One Level"></button>\n        </div>\n        <ul class="crumbs">\n            <li class="root_menu"><button class="nav_item">Main menu</button></li>\n        </ul>\n    </div>\n    <div class="children"></div>\n    <div class="section_links">\n        <p></p>\n        <ul></ul>\n    </div>\n    <div class="return">\n        <p></p>\n        <button><span class="icon"></span> Return to main menu</button>\n    </div>\n</nav>';
            this.#navParentDiv = this.#dialog.querySelector('div.nav_parent');
            this.#crumbsUl = this.#dialog.querySelector('ul.crumbs');
            this.#rootMenuCrumb = this.#crumbsUl.querySelector('li.root_menu');
            this.#rootMenuButton = this.#rootMenuCrumb.querySelector('button');
            this.#rootMenuButton.addEventListener('click', ()=>{this.#showItem(null);});
            this.#childrenDiv = this.#dialog.querySelector('div.children');
            this.#sectionLinksDiv = this.#dialog.querySelector('div.section_links');
            this.#sectionLinksP = this.#sectionLinksDiv.querySelector('p');
            this.#sectionLinksUl = this.#sectionLinksDiv.querySelector('ul');
            this.#returnDiv = this.#dialog.querySelector('div.return');
            this.#returnP = this.#returnDiv.querySelector('p');
            this.#returnButton = this.#returnDiv.querySelector('button');
            this.#returnButton.addEventListener('click', ()=>{this.#showItem(null);});
            this.#dialog.querySelector('.close_bar button').addEventListener('click', ()=>{this.#hide();});
            this.#navParentDiv.querySelector('button').addEventListener('click', ()=>{this.#showParent();})
            document.body.append(this.#dialog);
        }
    }

    #show()
    {
        if(this.#dialog && !this.#dialog.open)
        {
            // reset the menu ui
            this.#reset();

            // this is a hack to prevent scrolling while the dialog is visible as no browsers properly support overscroll-behavior: none on dialogs or their backdrops.
            document.body.classList.add('lightbox_showing');
            this.#dialog.showModal();
        }
    }

    #hide()
    {
        if(this.#dialog && this.#dialog.open)
        {
            // hide the dialog
            this.#dialog.close();

            // remove the overscroll hack
            document.body.classList.remove('lightbox_showing');
        }
    }

    /**
     * Updates the hamburger menu dialog to reflect the #currentItem
     */
    #updateDialog()
    {
        const self = this;
        function makeItemButton(item)
        {
            // console.log('makeItemButton:', item.title);
            const button = document.createElement('button');
            button.setAttribute('class', 'nav_item');
            if(item === self.#currentItem)
            {
                button.setAttribute('disabled', '');
            }
            button.textContent = item.title;
            button.addEventListener('click', ()=>{self.#showItem(item);});
            return button;
        }
        // upper_nav
        this.#dialog.querySelector('div.nav_parent').style.display = this.#isShowingRoot() ? 'none' : 'block';

        // recreate crumbs
        this.#crumbsUl.innerHTML = '';
        this.#crumbsUl.append(this.#rootMenuCrumb);
        if(this.#isShowingRoot())
        {
            this.#rootMenuButton.setAttribute('disabled', '');
        }
        else
        {
            this.#rootMenuButton.removeAttribute('disabled');
        }
        if(!this.#isShowingRoot())
        {
            for(const item of this.#currentItem.getAncestors())
            {
                let li = document.createElement('li');
                li.append(makeItemButton(item));
                this.#crumbsUl.append(li);
            }
            let li = document.createElement('li');
            li.append(makeItemButton(this.#currentItem));
            this.#crumbsUl.append(li);
        }

        // children
        function makeItemLink(item)
        {
            // console.log('makeItemLink:', item.title);
            const a = document.createElement('a');
            a.setAttribute('href', item.url);
            if(item.isCurrentPage())
            {
                a.classList.add('current_page');
            }
            a.textContent = item.title;
            return a;
        }

        function makeChildrenButton(item)
        {
            // console.log('makeChildrenButton:', item.title);
            const button = document.createElement('button');
            button.setAttribute('class', 'nav_button nav_children');
            button.setAttribute('title', `${item.title}: Show More`);
            button.addEventListener('click', ()=>{self.#showItem(item);});
            return button;
        }

        this.#childrenDiv.innerHTML = '';
        let children;
        if(this.#isShowingRoot())
        {
            children = [this.#getSiteNavRootItem()].concat(this.#topLevelItems);
        }
        else
        {
            children = this.#currentItem.children;
        }
        if(children)
        {
            const ul = document.createElement('ul');
            for(const child of children)
            {
                const li = document.createElement('li');
                li.append(makeItemLink(child));

                if(child.hasChildren())
                {
                    const div = document.createElement('div');
                    div.append(makeChildrenButton(child))
                    li.append(div);
                }

                ul.append(li);
            }
            this.#childrenDiv.append(ul);
        }


        // section links
        const topLevelNavItem = this.#isShowingRoot() ? null : this.#currentItem.getTopLevelNavItem();
        const hasUtilityLinks = (topLevelNavItem instanceof TopLevelNavItem && topLevelNavItem.utilityLinks instanceof Array && topLevelNavItem.utilityLinks.length > 0);
        this.#sectionLinksDiv.style.display = hasUtilityLinks ? 'block' : 'none';

        if(hasUtilityLinks)
        {
            this.#sectionLinksP.textContent = `Featured pages in ${topLevelNavItem.title}:`;
            this.#sectionLinksUl.innerHTML = '';
            for(const link of topLevelNavItem.utilityLinks)
            {
                let li = document.createElement('li');
                let a = document.createElement('a');
                a.setAttribute('href', link.url);
                a.textContent = link.title
                li.append(a);
                this.#sectionLinksUl.append(li);
            }
        }

        // return link
        if(topLevelNavItem instanceof TopLevelNavItem)
        {
            this.#returnP.textContent = `Not looking for information on ${topLevelNavItem.title}?`;
            this.#returnDiv.style.display = 'block';
        }
        else
        {
            this.#returnDiv.style.display = 'none';
        }
    }

    /**
     * Resets the hamburger menu UI by changing the current item to the initial item, and updating the dialog.
     * Called whenever the menu is shown.
     */
    #reset()
    {
        this.#currentItem = this.#initialItem;
        this.#updateDialog();
    }

    /**
     * Returns a boolean value indicating whether the current item is the root menu
     * @returns {boolean}
     */
    #isShowingRoot()
    {
        return this.#currentItem === null;
    }

    /**
     * Navigates to the current item's parent
     */
    #showParent()
    {
        if(!this.#isShowingRoot())
        {
            this.#currentItem = this.#currentItem.hasParent() ? this.#currentItem.parent : null;
            this.#updateDialog();
        }
    }

    /**
     * Navigates to the specified item. If item is null, then the root menu is shown
     * @param item {NavItem|null}
     */
    #showItem(item)
    {
        this.#currentItem = item;
        this.#updateDialog();
    }

    #loadNavData()
    {
        const self = this;

        function parseChild(o, parent)
        {
            let item = new NavItem(o.i, o.u, o.t, [], parent);
            self.#navItems[item.uid] = item;
            if(self.#initialItem===undefined && item.isCurrentPage())
            {
                self.#initialItem = item;
            }
            if(o.c)
            {
                for(const c of o.c)
                {
                    item.children.push(parseChild(c, item));
                }
            }
            return item;
        }

        // clear out the top level items array
        this.#topLevelItems.length = 0;

        for(const o of this.#navData)
        {
            let item = new TopLevelNavItem(o.i, o.u, o.t, [], [], o.m, o.s);
            this.#navItems[item.uid] = item;
            this.#topLevelItems.push(item);
            if(this.#initialItem===undefined && item.isCurrentPage())
            {
                this.#initialItem = item;
            }
            if(o.l)
            {
                for(const l of o.l)
                {
                    item.utilityLinks.push(new NavLinkItem(l.i, l.u, l.t, l.a));
                }
            }
            if(o.c)
            {
                for(const c of o.c)
                {
                    item.children.push(parseChild(c, item));
                }
            }

        }

        if(this.#initialItem===undefined)
        {
            // we didn't find a match for the current page in the loaded nav data, so set the initial item to the root menu (null)
            this.#initialItem = null;
        }
        else
        {
            // we matched an item for the current page; for the menu's initial state, we want to show the initial item's parent (ie: so the initial item is one of the displayed children)
            this.#initialItem = this.#initialItem.hasParent() ? this.#initialItem.parent : null;
        }
    }


    /**
     * Creates a fake NavItem for the 'Home' entry on the main menu
     * @returns {NavItem}
     */
    #getSiteNavRootItem()
    {
        return new NavItem(null, this.#siteNavRootUrl, 'Home', undefined, undefined);
    }
}


class NavItem
{
    constructor(uid, url, title, children, parent)
    {
        this.uid = uid;
        this.url = url;
        this.title = title;
        this.children = children;
        this.parent = parent;
    }

    hasChildren()
    {
        return typeof this.children === 'object' && this.children.length > 0;
    }

    hasParent()
    {
        return typeof this.parent === 'object';
    }

    /**
     * Gets a list of this item's ancestors in top-down order.
     * @returns {NavItem[]}
     */
    getAncestors()
    {
        let ancestors = [];
        for(let i=this; i.hasParent(); i=i.parent)
        {
            ancestors.push(i.parent);
        }
        ancestors.reverse();
        return ancestors;
    }

    /**
     * Gets the closest TopNavItem object in this item's ancestor line, or null if there isn't one.
     * @returns {NavItem|null}
     */
    getTopLevelNavItem()
    {
        for(let i=this;; i=i.parent)
        {
            if(i instanceof TopLevelNavItem)
            {
                return i;
            }
            if(!i.hasParent())
            {
                break;
            }
        }
        return null;
    }

    /**
     * Checks whether this item corresponds to the current page.
     * @returns {boolean}
     */
    isCurrentPage()
    {
        return new RegExp('^'+this.url+'[/]?$').exec(window.location.pathname) !== null
    }
}

class NavLinkItem
{
    constructor(uid, url, title, adminUrl)
    {
        this.uid = uid;
        this.url = url;
        this.title = title;
        this.adminUrl = adminUrl;
    }

}


class TopLevelNavItem extends NavItem
{
    constructor(uid, url, title, utilityLinks, children, menuTheme, isSection)
    {
        super(uid, url, title, children);
        this.utilityLinks = utilityLinks;
        this.menuTheme = menuTheme;
        this.isSection = isSection;
    }
}

console.debug('inz.nav2023 loaded');
/**
 *    -- INZ 2019 Details Javascript ------------------------------------------------------------------------------------------------------------------------
 *
 *    This is the Javascript file for <Component>. This is loaded after the original site javascript and after the inz2019.js core javascript.
 *
 */

/**
 *  Namespace object for the Details functionality.
 */
inz = inz || {};
inz.details = inz.details || {};
inz.details.bpStateMobile = false;
inz.details.bpStateTablet = false;

inz.details.init = function() {
    const mat = window.matchMedia('(max-width: 944px)'); // mobile and tablet
    mat.addEventListener('change', inz.details.bpTablet);
    const mbl = window.matchMedia('(max-width: 749px)'); // mobile
    mbl.addEventListener('change', inz.details.bpMobile);
    inz.details.bpTablet(mat);
    inz.details.bpMobile(mbl);

    // login handlers
    $(".vd_nb_apply_btn input").on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
    });
    inz.details.loginLightbox = new inz.Lightbox('#details_login_lightbox', '.vd_nb_apply_btn input');
    inz.details.hashChangeHandler();
    window.addEventListener('hashchange', inz.details.hashChangeHandler, false);
}

inz.details.hashChangeHandler = function(e) {
    if (window.location.hash.length > 1 ) {
        let sec = window.location.hash.substring(1);
        let sid;
        switch (sec) {
            case "overview":
                sid = "#overview";
                break;
            case "apply":
                sid = `#sc_${inz.details.findSectionByType("who_can_apply")}`;
                break;
            case "process":
                sid = `#sc_${inz.details.findSectionByType("process_and_costs")}`;
                break;
            case "submit":
                sid = `#sc_${inz.details.findSectionByType("how_to_submit")}`;
                break;
            case "points":
                sid = `#sc_${inz.details.findSectionByType("points")}`;
                break;
            case "conditions":
                sid = `#sc_${inz.details.findSectionByType("conditions")}`;
                break;
            default:
                if (sec.startsWith("sc_"))
                    sid = "#"+sec;
                break;
        }
        if (sid !== null) { // found section
            //_this.scrollNavbarForTab(this);
            let target = $(sid);
            if (target.length !== 0 ) {
                window.scrollTo({left: 0, top: (target.offset().top-$('.vd_navbar').outerHeight())+10, behavior: 'smooth'});
                setTimeout(function() { // hack around to handle size change in navbar
                    window.scrollTo({left: 0, top: (target.offset().top-$('.vd_navbar').outerHeight())+10, behavior: 'smooth'});
                }, 500);
            }
        }
    }
}

inz.details.findSectionByType = function(section_type) {
    let sections = document.querySelectorAll(".vd_section_container");
    for (let section of sections) {
        let vds = section.getAttribute("data-vds-type");
        if (vds === section_type) {
            return section.getAttribute("data-sid");
        }
    }
    return null;
}

inz.details.bpTablet = function(e) {
    if (e.matches) {
        //console.log("Tablet width!");
        inz.details.bpStateTablet = true;
    } else {
        //console.log("More than tablet width!");
        inz.details.bpStateTablet = false;
    }
    inz.details.breakpointChange(true, false);
}

inz.details.bpMobile = function(e) {
    if (e.matches) {
        //console.log("Mobile width!");
        inz.details.bpStateMobile = true;
    } else {
        //console.log("More than mobile width!");
        inz.details.bpStateMobile = false;
    }
    inz.details.breakpointChange(false, true);
}

inz.details.breakpointChange = function(tablet, mobile) {
    console.debug(`breakpoingChange: ${tablet}, ${mobile} - bpTable: ${inz.details.bpStateTablet} bpMobile: ${inz.details.bpStateMobile}`);
    if (tablet) {
        if(inz.details.bpStateTablet) {
            inz.accordion.attachEvents($('.vd_submit'));
        } else {
            inz.accordion.openAll($('.vd_submit section article .inz_accordion'), true);
            $('.vd_submit section article .process_title').off("click");
        }
    }
    if (mobile) {
        if (inz.details.bpStateMobile) {
            let apply = $("#vd_navbar .vd_nb_nav_tab_container > .vd_nb_apply_btn");
            if (apply.length === 1) {
                // apply button in tabs, move apply button below
                apply.detach().appendTo("#vd_navbar .vd_nb_nav_tab_backing");
            }
        } else {
            let apply = $("#vd_navbar .vd_nb_nav_tab_backing > .vd_nb_apply_btn");
            if (apply.length === 1) {
                // apply button not in tabs, move back
                apply.detach().appendTo("#vd_navbar .vd_nb_nav_tab_container");
            }
        }
    }
}

inz.details.dispatchEvent = function(srcElement, eventName, detail) {
    // state change fire event
    let event = new CustomEvent(eventName, {
        bubbles: true,
        detail: detail
    });
    srcElement.dispatchEvent(event);
}


$.fn.serializeObject = function() {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

inz.details.criteria = {};
inz.details.criteria.emptyTipsCheck = function() {
    // hide tips if none are visible
    $('.vd_criteria .inz_criteria_tips').each(function() {
        let last_tip = $(this).find(".inz_criteria_tip").not('.inactive').last();
        //if ($(this).find('.inz_criteria_tip').not('.inactive').length===0) {
        if (last_tip.length === 0) {
            $(this).addClass("hidden");
        } else {
            $(this).removeClass("hidden");
            last_tip.addClass("tip_last_vis");  // mark last tip
        }
    });
}

inz.details.initSections = function() {
    let sections = document.querySelectorAll("#main > .vd_section_container")
    sections.forEach(function(section) {
        let sid = section.getAttribute("data-sid");
        inz.details.sectionLoaded(sid);
    });
}

inz.details.sectionLoaded = function(sec_id) {

    let sec = $(`#sc_${sec_id}`).first();
    let sec_type = $(sec).attr("data-vds-type");

    switch (sec_type) {
        case "folder": {
            let vd_sec = $(sec).find(".vd_section").first();
            vd_sec.find(".vd_section").each(function() {
               inz.details.sectionLoaded($(this).attr("id"));
            });
            break; }
        case "how_to_submit":
            inz.details.breakpointChange(true, false);
            break;
        case "process_and_costs":
            // filter / show documents
            if (inz.details.navbar && inz.details.navbar.$filters) {
                let nat = inz.details.navbar.$filters.find("#filter_nationality").val();
                let res = inz.details.navbar.$filters.find("#filter_residence").val();
                $("#"+sec_id+" .process_documents .pdoc").each(function() {
                    let filters = $(this).find("[data-showonfilter]");
                    if(filters.length !== 0) {
                        filters.each(function() {
                            let vals = $(this).data("showonfilter").split(',');
                            for (let val of vals) {
                                val = val.trim();
                                if((val.startsWith("residence-") && val.substr(val.length-3) === res) || (val.startsWith("nationality-") && val.substr(val.length-3) === nat)) {
                                    $(this).toggleClass("inactive", false);
                                    break;  // it's shown, don't need to check others
                                }
                            }
                        });
                    }
                });
            }

            $("#"+sec_id+" .vd_currency_select").on("change", function(e) {
                let currency = $(this).val();
                $(this).closest(".vd_currency_group").find(".currency").hide();
                $(this).closest(".vd_currency_group").find(`[data-currency="${currency}"]`).show();
            })
            $("#"+sec_id+" .vd_currency_select").trigger("change");
            break;
        case "points":
            $(".vd_navbar .vd_nb_points").toggleClass("hidden", false);
            window.insertTerms();
            break;
        case "conditions":
            break;
        case "who_can_apply":
            $(".vd_criteria_view_all").click(function() {
                let container = $(this).closest(".vd_criteria");
                if ($(this).data("open")) {
                    //inz.accordion.closeAll($('.vd_criteria .inz_accordion'));
                    inz.accordion.closeAll(container.find('.inz_accordion'));
                    $(this).text("View All Evidence");
                    $(this).data("open", false);
                } else {
                    inz.accordion.openAll(container.find('.inz_accordion'));
                    $(this).text("Hide All Evidence");
                    $(this).data("open", true);
                }
            });

            inz.details.navbar.$navbar[0].addEventListener('inz:navbar_filters', function(e) {
                console.debug("@@@ Navbar filter event: "+JSON.stringify(e.detail));
                // <li data-flt-nat="nationality-EST, nationality-LVA, nationality-LTU" class="rt_content inactive">
                $('.vd_criteria [data-flt-nat]').each(function() {
                    if ($(this).data('flt-nat').includes(e.detail.nat)) {
                        $(this).removeClass("inactive");
                    } else {
                        $(this).addClass("inactive");
                    }
                });
                $('.vd_criteria [data-flt-res]').each(function() {
                    if($(this).data('flt-res').includes(e.detail.res)) {
                        $(this).removeClass("inactive");
                    } else {
                        $(this).addClass("inactive");
                    }
                });
                inz.details.criteria.emptyTipsCheck();
            });
            inz.details.criteria.emptyTipsCheck();
            break;
    }
}

inz.details.createNavbar = function(navbarEl) {
    inz.details.navbar = new inz.details.Navbar(navbarEl, true);
    inz.details.navbar.setup();
    return inz.details.navbar;
}

inz.details.createFilterNavbar = function(navbarEl, filterEl, filters, firstId) {
    inz.details.navbar = new inz.details.FilterNavbar(navbarEl, true, filterEl, filters, firstId);
    inz.details.navbar.setup();
    return inz.details.navbar;
}

inz.details.createSeparateNavbar = function(navbarEl) {
    inz.details.navbar = new inz.details.SeparateNavbar(navbarEl, false);
    inz.details.navbar.setup();
    return inz.details.navbar;
}


inz.details.getNavbar = function(navbarEl) {
    let navbar = $(navbarEl).data("navbar");
    return navbar;
}

inz.details.Navbar = class {
    /**
     * Init method for the Details Page called by the Details page late JS
     * @param {string} filterEl - Filter Element selector, where filters will go
     * @param {Object} filters - Filter map by id to objects, created in the View based on the passed Visa / Object
     * @param {string} firstId - Id of the first filter
     */
    constructor(navbarEl, overviewTab) {
        let _this = this;
        this.$navbar = $(navbarEl);
        this.$navbar.data("navbar", this);
        this.navbar = this.$navbar[0];
        this.navbarState = 0;
        this.navbarStuck = false;
        this.scrollTarget = null;
        this.hasFilters = false;

        this.sectionOrder = [];
        this.sections = {};
        this.sectionElements = {};

        this.navbarStickyIO = new IntersectionObserver(function(entries, observer) { _this.handleNavbarIntersect(entries, observer); },{ threshold: [0] });
        this.navbarStickyIO.observe(document.querySelector(".navbar_marker"));

        //let navbarSectionIO = new IntersectionObserver(function(entries, observer) { _this.handleNavbarSectionIntersect(entries, observer); },{ threshold: [0], rootMargin: "-176px 0px 0px 0px" });
        this.navbarSectionIO = new IntersectionObserver(function(entries, observer) { _this.handleNavbarSectionIntersect(entries, observer); },{ threshold: [0], rootMargin: "-220px 0px 0px 0px" });
        if (overviewTab)
            this.navbarSectionOvr = new IntersectionObserver(function(entries, observer) { _this.handleNavbarSectionIntersect(entries, observer); },{ threshold: [0], rootMargin: "-10px 0px 0px 0px" });

        //var sections = document.querySelectorAll(".vd_section_container_marker");
        let sections = document.querySelectorAll("#main > .vd_section_container")
        let idx = 0;
        this.sectionElements["overview"] = document.getElementById("overview");
        this.sectionElements["overview"].setAttribute("data-vds-type", "overview");
        this.sectionElements["overview"].setAttribute("data-sid", "overview");
        //this.sectionElements["overview"].closest("header");
        if (overviewTab)
            this.navbarSectionOvr.observe(this.sectionElements["overview"]);
        this.sections["overview"] = false;
        this.sectionOrder[idx++] = "overview";
        //let s = section.closest(".vd_top_section_container");
        //s.setAttribute("data-sid", sid);

        sections.forEach(function(section) {
            let sid = section.getAttribute("data-sid");
            let vds = section.getAttribute("data-vds-type");
            _this.sectionOrder[idx++] = sid;
            _this.sections[sid] = false;
            _this.sectionElements[sid] = section;
            _this.navbarSectionIO.observe(section);
        });

        /*document.addEventListener('inz:navbar_state', function(e) {
            var content = document.body;
            if (e.detail.isStuck) {
                console.log("event stuck");
                // var y = window.scrollY;
                // window.scrollBy(0, -99);
                // console.log("event stuck: ScrollY: "+window.scrollY);
                // $(".vd_nb_title").show().ready(function() {
                //     window.scrollTo(0, y);
                //     console.log("ScrollY 5: "+window.scrollY);
                // });
            } else {
                // console.log("UN ScrollY 1: "+window.scrollY);
                console.log("event UNstuck");
                // $(".vd_nb_title").hide();
                // $(".navbar_pad").hide();
                // console.log("UN ScrollY 2: "+window.scrollY);
            }
        });*/
    }

    setup() {
        this.setupNavbar(false);
        this.setupHandlers();
    }

    scrollNavbarForTab(tab_el) {
        // mobile scrolling navbar around
        if (tab_el === undefined) {
            return;
        }
        let cr = tab_el.getBoundingClientRect();
        let container_cr =$(".vd_navbar .vd_nb_nav_tabs")[0].getBoundingClientRect();
        let centre_x = container_cr.width / 2;
        let left = tab_el.offsetLeft - centre_x + cr.width/2;
        if (left < 0)
            left = 0;
        $(".vd_navbar .vd_nb_nav_tabs")[0].scroll({top: 0, left: left, behavior: 'smooth'});
    }

    setupHandlers() {
        let _this = this;
        $('.vd_navbar .vd_nb_nav_tabs_tab').click(function(e)
        {
            _this.scrollNavbarForTab(this);
            let target = $($(this).find("a").attr('href'));
            window.scrollTo({left:0, top:(target.offset().top-$('.vd_navbar').outerHeight())+10, behavior: 'smooth'});
            setTimeout(function() { // hack around to handle size change in navbar
                window.scrollTo({left:0, top:(target.offset().top-$('.vd_navbar').outerHeight())+10, behavior: 'smooth'});
            }, 500);
            e.preventDefault();
            e.stopPropagation();
        });
    }

    setupNavbar(hasFilters) {
        let config = {attributes: true, childList: false, subtree: false};
        let _this = this;

        _this.$navbar.on("affixed.bs.affix", function() {
            $(".navbar_filler").css("height", "200px");
            $(".navbar_filler").removeClass("hidden");
            if (hasFilters)  //if (!inz.details.navbarStuck)
            {
                _this.$navbar.find(".vd_nb_title").show(); // show visa title
                _this.openCloseFilters(false);  // close filters

                // notify - disabled for now
                /*if (_this.navbarState === 0)
                {
                    _this.navbarState = 1;
                    _this.$navbar.find('.vd_nb_tab').addClass('vd_notice');
                    _this.$navbar.find('.vd_nb_filter_sep').addClass('vd_notice');
                    _this.$navbar.find('.vd_navbar_notice').show();
                }*/
                //$(this).affix('checkPosition');
            }
            _this.navbarStuck = true;
            //$(this).affix('checkPosition');
        });

        _this.$navbar.on("affixed-top.bs.affix", function() {
            $(".navbar_filler").addClass("hidden");
            if (hasFilters) { //if (inz.details.navbarStuck)
                _this.$navbar.find(".vd_nb_title").hide();
                //$(this).affix('checkPosition');
            }
            _this.navbarStuck = false;
        });

        $(_this.$navbar.find('.vd_nb_tab a').on('click', function() {
            var $acc = $(_this.$navbar.find('.vd_nb_tab a'));

            if (hasFilters) {
                let isOpen = $acc.hasClass('active');

                /* Notify disabled for now
                if(_this.navbarState === 1) { // notify
                    _this.$navbar.find('.vd_nb_tab').removeClass('vd_notice');
                    _this.$navbar.find('.vd_nb_filter_sep').removeClass('vd_notice');
                    _this.$navbar.find('.vd_navbar_notice').hide();
                }
                _this.navbarState = 2;*/

                if(isOpen) {
                    setTimeout(function() {
                        var isOpen = $acc.hasClass('active');
                        if(isOpen)
                            $('#navbar_filters').css({'display': '', 'height': 'auto'});
                    }, 333);
                }
            }
        }));
    }

    dispatchNavbarEvent(eventName, detail) {
        // state change fire event
        let event = new CustomEvent(eventName, {
            bubbles: true,
            detail: detail
        });
        this.navbar.dispatchEvent(event);
    }

    stickNavbar() {
        $(".navbar_filler").css("height", "200px");
        $(".navbar_filler").removeClass("hidden");

        $('#vd_navbar').toggleClass("affix",true);
        $('#vd_navbar').toggleClass("affix-top",false);

        this.navbarStuck = true;
    }

    unstickNavbar() {
        $(".navbar_filler").addClass("hidden");

        $('#vd_navbar').toggleClass("affix",false);
        $('#vd_navbar').toggleClass("affix-top",true);
        this.navbarStuck = false;
    }

    handleNavbarIntersect(entries, observer) {
        let entry = entries[0];
        if (entry.isVisible || entry.boundingClientRect.top >= 0) {
            this.unstickNavbar();
            if (this.navbarStuck) {
                // state change fire event
                this.navbarStuck = false;
                this.dispatchNavbarEvent('inz:navbar_state', { isStuck: false});
            }
        } else if (entry.boundingClientRect.top < 0) {
            this.stickNavbar();
            if (!this.navbarStuck) {
                // state change fire event
                this.navbarStuck = true;
                this.dispatchNavbarEvent('inz:navbar_state', { isStuck: true});
            }
        }
    }

    handleNavbarSectionIntersect(entries, observer) {
        //console.log(entries);
        for (let idx in entries)
        {
            let entry = entries[idx];
            let sectionType = entry.target.getAttribute("data-vds-type");
            let sectionId = entry.target.getAttribute("data-sid");
            //console.log(entry);
            console.debug(entry.time+" - T: "+sectionType+" In: "+entry.isIntersecting+" Vis: "+entry.isVisible+" Ratio: "+entry.intersectionRatio+" Top: "+entry.boundingClientRect.top);
            this.sections[sectionId] = entry.isIntersecting;
        }

        for (let idx in this.sectionOrder)
        {
            let sectionId = this.sectionOrder[idx];
            if (this.sections[sectionId])
            { // is intersecting
                console.debug("ID: "+sectionId);
                $('.vd_nb_nav_tabs_tab').removeClass('current');
                //$('.vd_nb_nav_tabs_tab a[href="#'+sectionId+'"]').parent().addClass('current');
                let tgt = $('.vd_nb_nav_tabs_tab[data-sid="'+sectionId+'"]');
                tgt.addClass('current');
                this.scrollNavbarForTab(tgt[0]);
                break;
            }
        }
    }

    clearSections = function() {
        for (let idx in inz.details.sectionOrder)
        {
            let sectionId = inz.details.sectionOrder[idx];
            inz.details.sections[sectionId] = false;
        }
    }


}

inz.details.FilterNavbar = class extends inz.details.Navbar {
    /**
     * Init method for the Details Page called by the Details page late JS
     * @param {string} filterEl - Filter Element selector, where filters will go
     * @param {Object} filters - Filter map by id to objects, created in the View based on the passed Visa / Object
     * @param {string} firstId - Id of the first filter
     */
    constructor(navbarEl, overviewTab, filterEl, filters, firstId) {
        super(navbarEl, overviewTab);
        let _this = this;
        this.$filters = $(filterEl);
        this.$submit = $(filterEl).find('input[type="submit"]');
        this.filters = filters;
        this.hasFilters = true;

        // add first filter
        let firstFilter = this.filters[firstId];
        this.addFilter(this.$filters, firstFilter, 3);
        this.fitFilters();

        /*$('.vd_navbar .vd_nb_nav_tabs_tab a').click(function(e)  {
            // reset the mobile guide index (even if we're not currently at mobile width)
            // note: we have to intercept the a clicks to prevent the fragments from ending up in the browser history as
            //       otherwise this will prevent the Back link from working as expected

            let target = $($(this).attr('href'));
            // if we're at mobile width, we need to compensate for the height of the guide index at the top of the screen
            // we need to update the section title before we scroll, so that the guide index is now the correct height for the new title

            $('html,body').scrollTop(target.offset().top-$('.vd_navbar').outerHeight());

            //$('html,body').scrollTop(target.offset().top);
            //target.get(0).scrollIntoView({behavior: "smooth"});

            e.preventDefault();
            e.stopPropagation();
        });*/

        /*$(window).scroll(function() {
            _this.checkCurrentSection();
        });*/
    }

    setup() {
        this.setupHandlers();
        this.setupNavbar(true);
    }

    setupHandlers() {
        let _this = this;
        super.setupHandlers();
        this.$filters.find('.vd_nb_filter_input select').on('select2:select', function() {
            _this.submitEnable();
        });

        this.$filters.on('submit', function(e) {
            e.preventDefault();
            _this.filterUpdate();
            _this.openCloseFilters(false); // close filters
        });

        // hack around accordion sizing issues
        $('#navbar_filters').css({'display': '', 'height': 'auto'});
        setTimeout(function() {
            $('#navbar_filters').css({'display': '', 'height': 'auto'});
            _this.navbarChange();
        }, 333);
    }


    openCloseFilters(open) {
        let $acc = $(this.$navbar.find('.vd_nb_tab a'));
        let isOpen = $acc.hasClass('active');
        if (open && !isOpen) {
            $acc.trigger('click');
            $acc.addClass('active');
            setTimeout(function() { $('#navbar_filters').css({'display': '', 'height': 'auto'}); }, 333);
        } else if (!open && isOpen) {
            $acc.trigger('click');
            $acc.removeClass('active');
        }
    }

    fitFilters() {
        let $filters = $(inz.details.$filters).find(".vd_nb_filter_input");
        let numFilters = $filters.length;
        //console.log("Num Filters: "+numFilters);
        if (numFilters < 4) { // single line, remove all vd_nb_filter_wide
            $filters.each(function() {
                $(this).removeClass("vd_nb_filter_wide");
            });
        } else {
            if (numFilters === 4)
            { // double line, wide first two filters
                $($filters[0]).addClass("vd_nb_filter_wide");
                $($filters[1]).addClass("vd_nb_filter_wide");
            } else {
                // 5 or more,
                $filters.each(function() {
                    $(this).removeClass("vd_nb_filter_wide");
                });
            }
        }
    }

    focusFilters(event) {
        event.preventDefault();
        this.openCloseFilters(true);
    }

    /**
     * Tests filters to check if they all have values and if so submit button can be enabled, or if not it is disabled
     * @returns {boolean}
     */
    submitEnable() {
        let missingValue = false;
        this.$filters.find('.vd_nb_filter_input select').each(function() {
            if ($(this).val()==="")
                missingValue = true;
        });
        if (missingValue)
        {
            //console.log("Missing value!");
            this.$submit.prop("disabled", true);
        } else
        {
            //console.log("NOT missing value!");
            this.$submit.prop("disabled", false);
        }
        return !missingValue;
    }

    readFilters() {
        let values = sessionStorage.getItem('inz.details.filters');
        //console.log("Values: "+values);
    }

    storeFilters() {
        let values = inz.details.$filters.serialize();
        sessionStorage.setItem('inz.details.filters', values);
    }

    /**
     * Update sections after filters have been changed
     * AJAX's in the sections passing the new filter values
     */
    filterUpdate() {
        $.ajaxSetup({ cache: true });
        let _this = this;
        let values = this.$filters.serialize();
        let url = window.location.origin + window.location.pathname;

        // update overview expectation data
        /*$("div.vd_section .vd_overview[data-overview-su]").each(function()
        {
            var sec = $(this).attr("data-overview-su");

            var expMap = {};
            $(".vd_overview .vd_details .vd_expectation[data-exp-type]").each(function() {
                expMap[$(this).attr("data-exp-type")] = $(this);
            });

            console.log("Filter update: Overview Sec: "+sec+" "+this);
            $.getJSON(url+"?sec="+sec+"&"+values, function (data) {
                for (var i=0;i < data.exp.length; i++)
                {
                    if ("text" in data.exp[i])
                        expMap[data.exp[i].field].find('.vd_exp_text').html(data.exp[i].text);
                }
            });
        });*/

        $("div.vd_section[data-details-fu]").each(function() {
            let sec = $(this).attr("data-details-fu");
            let sec_id = $(this).attr("id");
            let sec_type = $("#sc_"+sec_id).data("vds-type");

            console.debug(`Filter update: Sec: ${sec} ${this} (${sec_type})`);

            $(this).parents(".vd_background").first().load( url+"?sec="+sec+"&"+values, function( response, status, xhr )
            {
                if ( status === "error" )
                {
                    let msg = "Sorry but there was an error: ";
                    console.log( msg + xhr.status + " " + xhr.statusText );
                } else if (status === "success") {
                    $(document).trigger("ajaxpages:contentloaded", {target: $("#"+sec_id)});  // enable sliding panel links
                    inz.details.sectionLoaded(sec_id);
                }
            });
        });

        // update event
        this.dispatchNavbarEvent('inz:navbar_filters', this.$filters.serializeObject());
        //console.log("Filter event: "+JSON.stringify(values));
    }

    shouldAddFilter(filter) {
        let count = 0;
        let citizenship = this.$filters.find("#filter_nationality").val();
        for (let opt of filter.options) {
            if (opt.citizenship === undefined || opt.citizenship === null) {
                count += 1;
            } else {
                if (opt.citizenship.includes(citizenship) === true)
                    count += 1;
            }
        }
        //console.log("Filter citizenship result: "+count);
        return count > 1;
    }

    /**
     * Add a filter to the filter block
     * @param el - Location to add the filter
     * @param filter {Object} - Filter definition
     * @param insertLocation {number} - Method of insertion, 0 = after, 1 = before, 2 = append, 3 = prepend
     */
    addFilter(el, filter, insertLocation) {
        // insertLocation: 0 = insertAfter, 1 = insertBefore, 2 = append, 3 = prepend
        // # ProductFilter { id, name, placeholder, options [{label, value}] data-theme="light" class="select_2-generic" style="width: 100%"
        let _this = this;

        let filterHtml = '<div class="vd_nb_filter_input"><select id="'+filter.id+'" name="'+filter.name+'" class="" data-placeholder="'+filter.placeholder+'" data-theme="light" class="select_2-generic" style="width: 100%">\
        <option></option>\
        <option value=" " disabled="disabled">'+filter.placeholder+'</option>';

        for (let i=0; i<filter.options.length; i++) {
            if (filter.options[i].next)
                filterHtml += `<option data-fltn="${filter.options[i].next}" value="${filter.options[i].value}">${filter.options[i].name}</option>`;
                //let opt = '<option data-fltn="'+filter.options[i].next+'" value="'+filter.options[i].value+'">'+filter.options[i].name+'</option>';
            else
                filterHtml += `<option value="${filter.options[i].value}">${filter.options[i].name}</option>`;
        }

        filterHtml += '</select></div>';
        if (el !== undefined) {
            switch(insertLocation) {
                case 0:
                    $(filterHtml).insertAfter(el);
                    break;
                case 1:
                    $(filterHtml).insertBefore(el);
                    break;
                case 2:
                    $(filterHtml).appendTo(el);
                    break;
                case 3:
                    $(filterHtml).prependTo(el);
                    break;
            }
        } else
            $("form#filters").append(filterHtml);

        if (filter.options.length<10)
            $("#"+filter.id).select2({theme:"light", "placeholder": filter.placeholder, "minimumResultsForSearch": "Infinity"});
        else
            $("#"+filter.id).select2({theme:"light", "placeholder": filter.placeholder});

        $("#"+filter.id).on('select2:select', function() {
            let opt = $(this).children("option:selected");
            let next = opt.attr('data-fltn');
            if (next !== undefined)
            {
                // remove any following filters
                $(this).parent().nextAll(".vd_nb_filter_input").remove();
                let flt = _this.filters[next];
                if (_this.shouldAddFilter(flt)) {
                    _this.addFilter($(this).parent(), flt, 0);
                    _this.fitFilters();
                } else {
                    _this.submitEnable();
                }
            } else
                _this.submitEnable();
        });
    }

    navbarChange() {
        console.debug("Navbar Change: "+this.$navbar.attr("class")+" State: "+this.navbarState);
        if (this.$navbar.hasClass('affix')) {
            this.navbarStuck = true;
            let orgHgt = this.$navbar.height();
            //console.log("Navbar Hgt: " + this.$navbar.height());
            this.$navbar.find(".vd_nb_title").show(); // show visa title
            this.openCloseFilters(false);  // close filters
            // notify - removed for now
            /*if (this.navbarState === 0) {
                this.navbarState = 1;
                this.$navbar.find('.vd_nb_tab').addClass('vd_notice');
                this.$navbar.find('.vd_nb_filter_sep').addClass('vd_notice');
                this.$navbar.find('.vd_navbar_notice').show();
            }*/
            let hgtChange = (this.$navbar.height() - orgHgt) - 1;
            //console.log("Navbar Hgt: " + this.$navbar.height()+" change: "+hgtChange);
            //window.scrollBy({top: -hgtChange, left: 0, behavior: 'smooth'});
        } else {
            if (this.navbarState === 1) {
                this.navbarState = 0;
                return;
            }
            this.navbarStuck = false;
            this.$navbar.find(".vd_nb_title").hide();
        }
    }

}

/**
 * Update navigation section highlight according to current page scroll location
 */
inz.details.checkCurrentSection = function()
{
    var topOfView = $('.vd_navbar').outerHeight();
    var sections = $('.vd_section');
    if (sections.length != 0)
    {
        if (inz.details.hasFilters && sections.length > 1 && topOfView <= sections[1].getBoundingClientRect().top)
        {
            $('.vd_nb_nav_tabs_tab').removeClass('current');
            $('.vd_nb_nav_tabs_tab a[href="#overview"]').parent().addClass('current');
            return;
        }
        for(var n = 0; n<sections.length; n++)
        {
            if(sections[n].getBoundingClientRect().bottom>=topOfView)
            {
                // console.log(sections[n]);
                // this section is visible
                $('.vd_nb_nav_tabs_tab').removeClass('current');
                $('.vd_nb_nav_tabs_tab a[href="#sc_'+sections[n].id+'"]').parent().addClass('current');
                //$('.guide_index a[href="#'+sections[n].id+'"]').addClass('current');
                // update the mobile current section title
                //inz.guides.updateSectionTitle(n+1, $(sections[n]).find('h1').first().text());
                break;
            }
        }
    }
    //inz.guides.collapseMobileGuideIndex();
};

inz.details.SeparateNavbar = class extends inz.details.Navbar {
    /**
     * Init method for the Details Page called by the Details page late JS
     * @param {string} filterEl - Filter Element selector, where filters will go
     * @param {Object} filters - Filter map by id to objects, created in the View based on the passed Visa / Object
     * @param {string} firstId - Id of the first filter
     */
    constructor(navbarEl, overviewTab) {
        super(navbarEl, overviewTab);
        let _this = this;
        let sections = document.querySelectorAll("#main > .vd_section_container");
        this.current = "overview";
        let idx = 0;
        sections.forEach(function(section) {
            let sid = section.getAttribute("data-sid");
            let vds = section.getAttribute("data-vds-type");
            if (vds !== "overview") {
                $(section).hide();
            }
        });

        let ov = document.getElementById("overview");
        ov.setAttribute("data-sid", this.sectionOrder[1]); // make overview equal first tab
    }

    setup() {
        this.setupHandlers();
        this.setupNavbar(false);
    }

    setupHandlers() {
        let _this = this;
        super.setupHandlers();

        $('.vd_navbar .vd_nb_nav_tabs_tab a').click(function(e) {
            e.preventDefault();
            //e.stopPropagation();
        });

        $('.vd_navbar .vd_nb_nav_tabs_tab').unbind("click"); // replace click handler
        $('.vd_navbar .vd_nb_nav_tabs_tab').click(function(e) {
            let sid =$(this).closest(".vd_nb_nav_tabs_tab").attr("data-sid");
            if (sid !== _this.current)
                _this.changeTab(sid);
        });
        this.current = this.sectionOrder[1]; // show first tab
        this.sectionElements[this.current].style.display='block';
    }

    changeTab(sid) {
        //console.log("Section: " + this.sectionElements[sid]);
        this.sectionElements[sid].style.display='block';
        if (this.current !== "overview") {
            this.sectionElements[this.current].style.display = 'none';
        }
        this.current = sid;
        window.location.hash = this.current === "overview" ? "#overview" : `#sc_${sid}`;
    }
}

/**
 *    -- INZ 2019 generic Content Javascript ------------------------------------------------------------------------------------------------------------------------
 *
 *    This is the Javascript file for generic Content. This is loaded after the original site javascript and after the inz2019.js core javascript.
 *
 *
 *    Because this is called after inz2019.js, the window.inz object is guaranteed to be available. DNA's custom jQuery build is also present.
 *
 *    General tips
 *    ------------
 *
 *    - Use jsdoc (https://jsdoc.app/) format to document your code. Be liberal with your comments - they are removed by the JSView when it serves the
 *      javascript resources in PRD, and well-commented code is a gift to future you and your co-workers!
 *
 *    - Wherever possible, do not rely on functionality that is provided via site.src.js. The longer term goal of the INZ2019 project is to remove the original
 *      site.src.js code from the application altogether. If you do need to depend on something from the old code, make sure you clearly identify it in the
 *      comments at the top of your js file to facilitate migration away at a future date.
 *
 *
 *    ES Support
 *    ----------
 *
 *    The minimum browser spec includes IE11, so given we're not using Babel etc, most ES6 and beyond language features are not available for use.
 *
 *    See: https://kangax.github.io/compat-table/es6/ for the bad news.
 *
 *    Worse still, this means that testing on IE11 is very much required before shipping any code changes.
 *
 */

/**
 *  Namespace object for the generic Content functionality.
 */
inz.genericContent = {};


/**
 * Init method for the generic Content functionality; called from inz.inz2019Ready() (inz2019.js) on document ready.
 */
inz.genericContent.init = function()
{
    document.addEventListener(inz.viewport.EVENT_INZ_ADJUST_LAYOUT, () =>
    {
        inz.genericContent.updateFullWidthImages();
    });
};

inz.genericContent.updateFullWidthImages = function()
{
    let extendToLeftEdge = !inz.viewport.isTabletOrSmaller();
    let nl = document.querySelectorAll('.inz_main_column .inz_image.inz_image_full_width');
    for(let n of nl)
    {
        if(extendToLeftEdge)
        {
            n.style.marginLeft = (0-n.parentElement.offsetLeft-n.offsetParent.offsetLeft)+'px';
        }
        else
        {
            n.style.marginLeft = null;
        }
    }
};


// inz.genericContent.updateImagesWithAssociatedContent = function()
// {
//     let extendToLeftEdge = !inz.viewport.isTabletOrSmaller();
//     let nl = document.querySelectorAll('.inz_main_column .inz_image.inz_image_with_associated_content');
//     for(let n of nl)
//     {
//         let leftExtensionPixels = extendToLeftEdge ? n.offsetParent.offsetLeft : 0;
//         n.style.marginLeft = (0-n.parentElement.offsetLeft-leftExtensionPixels)+'px';
//         n.style.paddingLeft = leftExtensionPixels+'px';
//         n.style.marginRight = 0-(n.offsetParent.offsetWidth-n.parentElement.offsetWidth-n.parentElement.offsetLeft)+'px';
//     }
// };


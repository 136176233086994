/**
 *    -- INZ 2019 Common Layout Javascript ------------------------------------------------------------------------------------------------------------------------
 *
 *    This is the Javascript file for Common Layout. This is loaded after the original site javascript and after the inz2019.js core javascript.
 *
 *    Because this is called after inz2019.js, the window.inz object is guaranteed to be available. DNA's custom jQuery build is also present.
 *
 *    General tips
 *    ------------
 *
 *    - Use jsdoc (https://jsdoc.app/) format to document your code. Be liberal with your comments - they are removed by the JSView when it serves the
 *      javascript resources in PRD, and well-commented code is a gift to future you and your co-workers!
 *
 *    - Wherever possible, do not rely on functionality that is provided via site.src.js. The longer term goal of the INZ2019 project is to remove the original
 *      site.src.js code from the application altogether. If you do need to depend on something from the old code, make sure you clearly identify it in the
 *      comments at the top of your js file to facilitate migration away at a future date.
 *
 *
 *    ES Support
 *    ----------
 *
 *   Internet Explorer (including IE11) are no longer supported browsers, so use of ES6 and beyond language features are now fair game.
 *   That said, we continue to use babel to generate theoretically compatible js for ES5-limited platforms.
 *
 */

/**
 *  Namespace object for the Common Layout functionality.
 */
inz.commonLayout = {};
inz.commonLayout.elements = {};


/**
 * Init method for the commonLayout functionality; called from inz.inz2019Ready() (inz2019.js) on document ready.
 */
inz.commonLayout.init = function()
{
    // define some useful element references
    inz.commonLayout.elements.bottomRightOverlays = $('#bottom_right_overlays');
    inz.commonLayout.elements.inzPageHeader = $('header.inz_page_header');
    inz.commonLayout.elements.backToTopButton = null;  // set in initBackToTop()

    // perform the init
    inz.commonLayout.initBackToTop();

};


/**
 * Initialise the Back to Top button if requested by the page.
 */
inz.commonLayout.initBackToTop = function()
{
    if(!inz.commonLayout.isBackToTopRequested())
    {
        return;
    }

    // add the button to the #bottom_right_overlays div
    inz.commonLayout.elements.backToTopButton = $('<button>',
        {
            id: 'back_to_top', title: 'Back to Top', click: ()=>
            {
                window.scrollTo(0, 0);
                inz.commonLayout.updateBackToTopButtonPosition();
            }
        });
    inz.commonLayout.elements.bottomRightOverlays.prepend(inz.commonLayout.elements.backToTopButton);

    // register a scroll listener to check whether the Back To Top Button needs to have its visibility changed
    // had to use window's scroll event rather than the window.visualViewport's scroll event because Chrome doesn't seem to trigger the latter
    window.addEventListener('scroll', ()=>{inz.commonLayout.updateBackToTopButtonPosition();});

    // register a transition listener on the Back To Top Button to update its display property after it moves on-/off-screen
    inz.commonLayout.elements.backToTopButton.on('transitionend', ()=>{inz.commonLayout.updateBackToTopButtonDisplay();});

    // evaluate Back To Top visibility in page's initial state
    inz.commonLayout.updateBackToTopButtonPosition();
    inz.commonLayout.updateBackToTopButtonDisplay();


    // add the 'with_transition' class to enable animation now that we've positioned ourselves correctly for our initial state
    setTimeout(()=>{inz.commonLayout.elements.backToTopButton.addClass('with_transition');}, 250);
}

/**
 * Logic method to determine if the page has requested the Back to Top button be displayed on this page.
 * @returns boolean
 */
inz.commonLayout.isBackToTopRequested = function()
{
    return $(document.body).is("[data-back-to-top]");
}

/**
 * Checks whether the header is out of view as the basis for whether the Back to Top button should be shown.
 * On pages where there is no appropriate header, fall back to whether the viewport has been scrolled more than 33% of its height.
 * @returns boolean
 */
inz.commonLayout.isHeaderOutOfView = function()
{
    let result;
    if(inz.commonLayout.elements.inzPageHeader.length)
    {
        // the page has an INZ Page Header, so our check is whether that is currently on-screen
        result = inz.commonLayout.elements.inzPageHeader.offset().top+inz.commonLayout.elements.inzPageHeader.outerHeight()<window.visualViewport.pageTop;
    } else
    {
        // no INZ Page Header, so we fall back to checking whether we've scrolled down more than a third of the viewport height
        result = window.visualViewport.pageTop/window.visualViewport.height>0.33;
    }
    // console.log('isHeaderOutOfView:', result);
    return result;
}

/**
 * Logic check to determine whether the Back to Top button should currently be showing.
 * Note: this doesn't check whether the Back to Top button is enabled on the current page - it is assumed that if this method is being called then the Back to Top button is enabled.
 * @returns boolean
 */
inz.commonLayout.shouldShowBackToTop = function()
{
    return inz.commonLayout.isHeaderOutOfView();
}

/**
 * Updates the Back to Top Button's position by adjusting the left margin to scroll it on/off the screen.
 * The margin change is subject to a css transition which implements the actual scrolling animation.
 */
inz.commonLayout.updateBackToTopButtonPosition = function()
{
    if(inz.commonLayout.elements.backToTopButton.hasClass('hidden') && inz.commonLayout.shouldShowBackToTop())
    {
        // button is currently hidden, but is about to be scrolled into view below, so we need to set it to display inline-block first
        inz.commonLayout.updateBackToTopButtonDisplay();
    }
    inz.commonLayout.elements.backToTopButton.toggleClass('hidden', !inz.commonLayout.shouldShowBackToTop());
}

/**
 * Update the display property on the Back to Top Button.
 * This is called separately from updating the position, because it has to be changed independently of the positioning animation, via transition listeners.
 */
inz.commonLayout.updateBackToTopButtonDisplay = function()
{
    inz.commonLayout.elements.backToTopButton.css('display', inz.commonLayout.shouldShowBackToTop() ? 'inline-block' : 'none');
}